'use client'

import React, {useMemo, useState} from 'react'

import {CMSMainHomepageChannel} from 'cms-types'
import {Icon} from 'layout/Icon'
import {Link} from 'layout/Link'
import Modal from 'react-modal'
import css from './Mobilemenu.module.scss'
import dynamic from 'next/dynamic'
import {fromModule} from 'util/styler/Styler'
import {nl} from 'locale'
import {usePathname} from 'next/navigation'

const styles = fromModule(css)
const DynamicLogo = dynamic(() => import('../../../public/logo.svg'))

type MenuItem = {
	id: number
	url: string
	title: string
	description?: string
	parent?: MenuItem
	children?: Array<MenuItem>
}

type Menu = {
	root: Array<MenuItem>
	all: Map<number, MenuItem>
}

function mapItems(nav: CMSMainHomepageChannel['header_nav']): Menu {
	const all: Menu['all'] = new Map<number, MenuItem>()
	const root: Menu['root'] = []
	if (nav) {
		for (const {category, subcategories} of nav) {
			const children: MenuItem['children'] = []
			const rootItem = {...category, children}
			root.push(rootItem)
			all.set(category.id, rootItem)
			for (const {subcategory, sublinks} of subcategories) {
				const subchildren: MenuItem['children'] = []
				const subItem = {
					...subcategory,
					parent: rootItem,
					children: subchildren
				}
				children.push(subItem)
				all.set(subcategory?.id, subItem)
				for (const sublink of sublinks) {
					if (sublink.type !== 'entry') continue
					const subLink = {...sublink, parent: subItem}
					subchildren.push(subLink)
					all.set(sublink.id, subLink)
				}
			}
		}
	}
	return {root, all}
}

export const Mobilemenu: React.FC<{
	modalOpen?: boolean
	onClose?: () => void
	header_nav: CMSMainHomepageChannel['header_nav']
	header_btn: CMSMainHomepageChannel['header_btn']
}> = ({modalOpen, onClose, header_nav, header_btn}) => {
	const menu = useMemo(() => mapItems(header_nav), [header_nav])
	const [selected, setSelected] = useState<number | undefined>()

	return (
		<Modal
			isOpen={modalOpen}
			onRequestClose={onClose}
			closeTimeoutMS={300}
			ariaHideApp={false}
			portalClassName={styles.mobilemenu()}
			overlayClassName={styles.mobilemenu.overlay()}
			className={styles.mobilemenu.content()}
		>
			<MobilemenuTop onClose={onClose} />
			<MobilemenuNav
				onClose={onClose}
				selected={selected}
				onSelect={setSelected}
				menu={menu}
				header_btn={header_btn}
			/>
		</Modal>
	)
}

const MobilemenuTop: React.FC<{onClose?: () => void}> = ({onClose}) => {
	return (
		<div className={styles.top()}>
			<Link
				href="/"
				onClick={onClose}
				title={nl.project_name}
				className={styles.top.logo()}
			>
				<DynamicLogo />
			</Link>
			<button onClick={onClose} className={styles.top.close()}>
				<Icon icon="close" />
			</button>
		</div>
	)
}

const MobilemenuNav: React.FC<{
	onClose?: () => void
	selected: number | undefined
	onSelect: (id: number | undefined) => void
	menu: Menu
	header_btn: CMSMainHomepageChannel['header_btn']
}> = ({onClose, selected, onSelect, menu, header_btn}) => {
	const selectedItem: MenuItem | null =
		(selected && menu.all.get(selected)) || null
	const items = selectedItem?.children || menu.root

	return (
		<nav className={styles.nav()}>
			{selectedItem && (
				<button
					onClick={() => onSelect(selectedItem.parent?.id)}
					className={styles.nav.parent()}
				>
					<Icon icon="arrow_left" />
					<span className={styles.nav.parent.title()}>
						{selectedItem.title}
					</span>
				</button>
			)}
			{items.map((item, i) => {
				return (
					<div className={styles.nav.item()} key={i}>
						<div className={styles.nav.item.row()}>
							<MobilemenuLink
								url={item.url}
								title={item.description || item.title}
								onClose={onClose}
							/>
							{item.children && item.children.length > 0 && (
								<a
									onClick={() => onSelect(item.id)}
									className={styles.nav.item.row.arrow()}
								>
									<Icon icon="arrow_right" />
								</a>
							)}
						</div>
					</div>
				)
			})}
			{!selectedItem && header_btn?.url && (
				<div className={styles.nav.item()}>
					<MobilemenuLink
						url={header_btn.url}
						title={header_btn.description || header_btn.title}
						onClose={onClose}
					/>
				</div>
			)}
		</nav>
	)
}

const MobilemenuLink: React.FC<{
	url: string
	title: string
	onClose?: () => void
}> = ({url, title, onClose}) => {
	const pathname = usePathname()
	if (!url) return null
	const isActive = pathname === url || (pathname && pathname.startsWith(url))

	return (
		<Link
			href={url}
			onClick={onClose}
			className={styles.link.is({active: isActive})()}
		>
			{title}
		</Link>
	)
}
