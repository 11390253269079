'use client'

import React, {Fragment, useState} from 'react'

import {CMSMainHomepageChannel} from 'cms-types'
import {Mobilemenu} from './Mobilemenu'
import css from './HeaderHamburger.module.scss'
import {fromModule} from 'util/styler/Styler'
import {nl} from 'locale'

const styles = fromModule(css)

export const HeaderHamburger: React.FC<{
	header_nav: CMSMainHomepageChannel['header_nav']
	header_btn: CMSMainHomepageChannel['header_btn']
}> = ({header_nav, header_btn}) => {
	const [openMobilemenu, setOpenMobilemenu] = useState<boolean>(false)

	return (
		<Fragment>
			<button
				onClick={() => setOpenMobilemenu(!openMobilemenu)}
				aria-label={nl.header.hamburger.arialabel}
				aria-expanded={openMobilemenu ? true : false}
				className={styles.headerhamburger()}
			>
				<span className={styles.headerhamburger.bar()} />
				<span className={styles.headerhamburger.bar()} />
				<span className={styles.headerhamburger.bar()} />
			</button>
			<Mobilemenu
				modalOpen={openMobilemenu}
				onClose={() => setOpenMobilemenu(false)}
				header_nav={header_nav}
				header_btn={header_btn}
			/>
		</Fragment>
	)
}
