'use client'

import {
	CMSEntryLink,
	CMSExternalLink,
	CMSMainHomepageChannel,
	CMSWysiwyg
} from 'cms-types'

import {Container} from 'layout/Container'
import {Link} from 'layout/Link'
import React from 'react'
import {Wysiwyg} from 'layout/wysiwyg/Wysiwyg'
import css from './HeaderNav.module.scss'
import {fromModule} from 'util/styler/Styler'
import {usePathname} from 'next/navigation'

const styles = fromModule(css)

export const HeaderNav: React.FC<{
	header_nav: CMSMainHomepageChannel['header_nav']
	has_subnavigation?: boolean
}> = ({header_nav, has_subnavigation}) => {
	const pathname = usePathname()
	if (!header_nav || header_nav.length === 0) return null

	return (
		<nav className={styles.headernav.mod({subnavigation: has_subnavigation})()}>
			{header_nav.map((item, i) => {
				if (!item?.category?.url) return null
				const isActive =
					pathname === item.category.url ||
					pathname?.startsWith(item.category.url)

				return (
					<div className={styles.headernav.item()} key={i}>
						<Link
							href={item.category.url}
							className={styles.headernav.item.category.is({
								active: isActive
							})()}
						>
							{item.category.description || item.category.title}
						</Link>
						<HeaderDropdown
							description={item.description}
							subcategories={item.subcategories}
						/>
					</div>
				)
			})}
		</nav>
	)
}

const HeaderDropdown: React.FC<{
	description: CMSWysiwyg
	subcategories: {
		subcategory: CMSEntryLink
		sublinks: (CMSEntryLink | CMSExternalLink)[]
	}[]
}> = ({description, subcategories}) => {
	if (
		!subcategories ||
		subcategories.length === 0 ||
		!subcategories[0].subcategory
	)
		return null

	return (
		<div className={styles.dropdown()}>
			<Container>
				{description && (
					<Wysiwyg
						html={description}
						className={styles.dropdown.description()}
					/>
				)}
				<div className={styles.dropdown.row()}>
					{subcategories.map((item, i) => (
						<div className={styles.dropdown.row.col()} key={i}>
							<HeaderSubcategory
								subcategory={item.subcategory}
								sublinks={item.sublinks}
							/>
						</div>
					))}
				</div>
			</Container>
		</div>
	)
}

const HeaderSubcategory: React.FC<{
	subcategory: CMSEntryLink
	sublinks: (CMSEntryLink | CMSExternalLink)[]
}> = ({subcategory, sublinks}) => {
	const pathname = usePathname()
	if (!subcategory && (!sublinks || sublinks?.length === 0)) return null

	const isActive =
		subcategory &&
		(pathname === subcategory.url || pathname?.startsWith(subcategory.url))

	return (
		<div className={styles.subcategory()}>
			{subcategory?.url && (
				<Link
					href={subcategory.url}
					className={styles.subcategory.link.is({active: isActive})()}
				>
					{subcategory.description || subcategory.title}
				</Link>
			)}
			{sublinks?.length > 0 && (
				<div className={styles.subcategory.sublinks()}>
					{sublinks.map((link, i) => {
						if (!link?.url) return null

						const subIsActive =
							pathname === link.url || pathname?.startsWith(link.url)

						return (
							<div className={styles.subcategory.sublinks.item()} key={i}>
								<Link
									href={link.url}
									className={styles.subcategory.sublinks.item.link.is({
										active: subIsActive
									})()}
								>
									{link.description || link.title}
								</Link>
							</div>
						)
					})}
				</div>
			)}
		</div>
	)
}
