'use client'

import React, {useEffect} from 'react'

const ThemeSwitcher: React.FC<{theme: string}> = ({theme}) => {
	useEffect(() => {
		document.body.dataset.theme = theme
	}, [theme])
	return <></>
}
export default ThemeSwitcher
