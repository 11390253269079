'use client'

import {Container} from 'layout/Container'
import {Link} from 'layout/Link'
import React from 'react'
import css from './Subnavigation.module.scss'
import {fromModule} from 'util/styler/Styler'
import {usePathname} from 'next/navigation'

const styles = fromModule(css)

export type SubnavigationItem = {id: number; url: string; title: string}
export type Subnavigation = SubnavigationItem[]

export const Subnavigation: React.FC<{
	nav: Subnavigation | null
}> = ({nav}) => {
	const pathname = usePathname()
	if (!nav || nav.length === 0) return null

	return (
		<nav className={styles.subnavigation()}>
			<Container>
				<div className={styles.subnavigation.items()}>
					{nav.map((link) => {
						if (!link?.url) return null
						const isActive =
							pathname === link.url || pathname?.startsWith(link.url)

						return (
							<div className={styles.subnavigation.items.item()} key={link.id}>
								<Link
									href={link.url}
									className={styles.subnavigation.items.item.link.is({
										active: isActive
									})()}
								>
									{link.title}
								</Link>
							</div>
						)
					})}
				</div>
			</Container>
		</nav>
	)
}
